import styled from "@emotion/styled";
import { SpotlightProvider } from "@mantine/spotlight";
import { useChatSpotlightProps } from "../spotlight";
import Header, { HeaderProps, SubHeader } from "./header";
import MessageInput from "./input";
import SettingsDrawer from "./settings";
import Sidebar from "./sidebar";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "./settings/page.css";
import Form from "react-bootstrap/Form";
import { Dropdown, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { SOCIAL_CARE } from "../values";
import { Fab } from "@material-ui/core";
import searchIcon from "../assets/Archive/carbon_send-alt-filled.png";
import PricingCards from "./pages/pricing-card";
import * as idb from "../idb";
import defaultImage from "../assets/Archive/default.jpg";

const userName = localStorage.getItem("username");

const FooterContainer = styled.footer`
  background: #000;
  color: white;
  padding: 1rem;
  text-align: center;
  z-index: 2;
  margin-top: auto;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const StyledFab = styled(Fab)`
  position: relative;
  height: 30px;
  width: 36px;
  background-color: #272727;
  color: white;
  opacity: 1;
  margin: 12px;
  z-index: 3;
  transition: all 0.2s;
  :hover {
    background-color: #272727;
    opacity: 0.9;
  }
`;


const checkUserType = async () => {
  try {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const apiUrl = `https://api.thecarepro.co.uk/api/v1/account/status/${userId}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(apiUrl, requestOptions);
    if(response.status === 401)
      return false;
    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
      
    toast.error("An unexpected error occurred. Please try again later.");
    
  }
};

export function Page(props: {
  id: string;
  headerProps?: HeaderProps;
  showSubHeader?: boolean;
  showSidebar?: boolean;
  children: any;
  style?: any;
}) {
const navigate = useNavigate();
  
  const spotlightProps = useChatSpotlightProps();
  
  const [usertype, setUsertype] = useState(false);
  const [showSubPage, setShowSubPage] = useState(true);
  
  const [userActive, setUserActive] = useState(true);

  useEffect(() => {
    // console.log("Setting up logout timer...");
    // Set timer for automatic logout after 10 minutes of inactivity
    let logoutTimer = setTimeout(() => {
      setUserActive(false);
      // console.log("5 seconds passed. Logging out...");
      handleLogout();
    }, 10 * 60 * 1000); // 5 seconds in milliseconds
  
    // Reset timer on user activity
    const resetTimer = () => {
      clearTimeout(logoutTimer);
      // console.log("Timer reset.");
      // Set up the timer again
      logoutTimer = setTimeout(() => {
        setUserActive(false);
        // console.log("5 seconds passed. Logging out...");
        handleLogout();
      }, 10 * 60 * 1000); // 5 seconds in milliseconds
    };
  
    // Add event listeners for user activity
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
  
    // Clear user activity listeners and timer on component unmount
    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      clearTimeout(logoutTimer);
    };
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await checkUserType();
        if(userData == false){
          handleLogout();
        }
        setUsertype(userData?.payload?.Free);
      } catch (error) {
        navigate("/login")
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleChildCallback = (data) => {
    console.log("Callback from child PAGE:", data);
    setShowSubPage(data);
  };

  const handleGoToChat = () => {
    setShowSubPage(true);
  };

  const handleLogout = async () => {
    await idb.del("chats");
    localStorage.setItem("cureSpaceUserLogged", JSON.stringify(false));
    window.location.reload();
    // localStorage.removeItem("token");
    // localStorage.removeItem("cureSpaceUserLogged");
    // localStorage.removeItem("userType");
    // localStorage.removeItem("userId");
    localStorage.clear()
  };

  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem("token");
      const apiUrl = `https://api.thecarepro.co.uk/api/v1/stripe/cancel-subscription`;
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await fetch(apiUrl, requestOptions);
      if(response.status === 401)
        return false;
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      setUsertype(true)
      toast.success('Successfully Cancelled Subscription!');
    } catch (error) {
        
      toast.error("An unexpected error occurred. Please try again later.");
      
    }
  };

  return (
    <SpotlightProvider {...spotlightProps}>
      <div className="layout">
        {props.showSidebar !== false && (
          <Sidebar onChildCallback={handleChildCallback} />
        )}

        <div
          className="container-page"
          // style={{
          //   left: `${props.showSidebar !== false ? "20%" : 0}`,
          //   ...props.style,
          // }}
          key={props.id}
        >
          <div className="row reverse m-0">
            <div className="col-md-4 offset-md-3 col-lg-4 offset-lg-4  col-xl-3 offset-xl-5">
              <Form.Group className="mb-3 mt-4" controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  className="form-control"
                  placeholder="Write a mesage"
                />
              </Form.Group>
            </div>

            <div className="col-md-4">
              <Dropdown className="mt-2">
                <Dropdown.Toggle id="dropdown-basic" className="dropdown montserrat">
                  <img
                    src={defaultImage}
                    width={"50px"}
                    height={"50px"}
                    className="rounded-circle"
                    alt=""
                  />{" "}
                  {userName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {usertype === false && ( 
                      <Dropdown.Item eventKey="John Smith" className="montserrat">
                        <div onClick={handleCancelSubscription}>
                          Cancel Subscription
                        </div>
                      </Dropdown.Item>
                    )}
                  <Dropdown.Item eventKey="John Smith" className="montserrat" onClick={handleLogout}>
                    Sign Out
                  </Dropdown.Item>
                  {/* Add more profiles as needed */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* <Header
            share={props.headerProps?.share}
            canShare={props.headerProps?.canShare}
            title={props.headerProps?.title}
            onShare={props.headerProps?.onShare}
            usertype={usertype}
          /> */}
          {/* {props.showSubHeader && <SubHeader />} */}
          {showSubPage ? (
            <>
              {props.children}
              <MessageInput key={localStorage.getItem("openai-api-key")} />
              <SettingsDrawer />
            </>
          ) : (
            <>
              <div className="container-fluid mb-5">
                <div>
                  <PricingCards></PricingCards>
                </div>
                <div
                  className="row text-center col-md-2 col-sm-1 offset-md-5 offset-4 col-4 mt-2"
                  onClick={handleGoToChat}
                >
                  <Button className="purple mx-auto py-2 mt-5">Go To Chat</Button>
                </div>
              </div>
            </>
          )}

          <FooterContainer>
            <FooterContent>
              <nav
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Link to="/terms">
                  <div>
                    <u className="footer-text text-white fs-6">
                      Terms of Service
                    </u>
                  </div>
                </Link>
                <Link to="/privacy">
                  <div>
                    <u className="footer-text">Privacy Policy</u>
                  </div>
                </Link>
              </nav>
              <SocialMediaContainer style={{ gap: "1.5rem" }} className="mt-3">
              <a
                href="https://www.facebook.com/thecarepro"
                target="_blank"
                rel="noopener noreferrer"
              ><FaFacebook size={25} color="#4267B2" /></a>
                
                {/* <FaTwitter size={25} color="#1DA1F2" /> */}
                <a
                href="https://www.tiktok.com/@thecarepro.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              ><FaTiktok size={24} /></a>
              
              <FaInstagram size={25} color="#E4405F" />
              <a
                href="https://youtube.com/@thecarepro1"
                target="_blank"
                rel="noopener noreferrer"
              ><FaYoutube size={25} color="#E4405F" /></a>
              </SocialMediaContainer>
              {/* <hr className="horizontal-bar" /> */}

              {/* <div style={{ marginBottom: "8px " }} className="montserrat">
                &copy; {new Date().getFullYear()} {SOCIAL_CARE}. All rights
                reserved.
              </div> */}
            </FooterContent>
          </FooterContainer>
        </div>
      </div>
    </SpotlightProvider>
  );
}
